export default {
    "chat": {
        "press_name": "Атыңызды енгізіңіз",
        "new_chat": "Жаңа диалог / Топтық чат",
        "chats": "Чаттар",
        "contacts": "Байланыстар",
        "create_meeting": "Бейне конференцияны құру",
        "admin": "Әкімші",
        "press_message": "Хабарлама енгізіңіз",
        "participant": "қатысушы",
        "participant2": "қатысушыны",
        "participant3": "қатысушылар",
        "error": "Қате",
        "owner": "Автор",
        "to_answer": "Жауап беру",
        "remove": "Жою",
        "set_task": "Тапсырма қою",
        "personal_chat": "Жеке чат",
        "file_and_image": "Файл немесе сурет",
        "systemic": "Жүйелі",
        "chat_information": "Чат туралы ақпарат",
        "chat_delete": "Чатты жою",
        "chat_delete_text": "Сіз шынымен чатты жойғыңыз келе ме?",
        "yes": "Иә",
        "no": "Жоқ",
        "close": "Жабу",
        "edit": "Атын өзгерту",
        "add_user": "Қатысушы қосу",
        "uncover": "Ашу",
        "collapse": "Құлату",
        "moderate": "Модератор",
        "pick_up_a_moderator": "Модераторды жою",
        "submit_a_moderator": "Модератор етіп тағайындау",
        "exclude": "Шығару",
        "file_max_count": "Хабарламада тек {count} файл жібере аласыз",
        "file_size_error": "Файл {name} {filesize} мегабайттан асқан!",
        "file_modal_title": "Файлмен хабарлама жіберу",
        "send": "Жіберу",
        "add_task": "Тапсырма қосу",
        "select_date": "Күнді таңдау",
        "select_performer": "Орындаушыны таңдау",
        "author": "Автор",
        "select_author": "Авторды таңдау",
        "observers": "Бақылаушылар",
        "actions": "Әрекеттер",
        "add": "Қосу",
        "chat_message": "Чаттағы хабарламалар",
        "comment": "Пікір",
        "create_task": "Тапсырма құру",
        "change": "Өзгерту",
        "anchor": "Тұзақтау",
        "unpin": "Босату",
        "pinned_message": "Тұзақталған хабарлама #{index}",
        "pinned_messages": "Тұзақталған хабарламалар",
        "unpin_all": "Барлығын босату",
        "show_in_chat": "Чатта көрсету",
        "moderator": "Модератор",
        "create_group_chat": "Топтық чат құру",
        "chat_name": "Чаттың атауы",
        "chat_conference": "{name} чат конференциясы",
        "task_conference": "{name} тапсырма конференциясы",
        "task_list": "Тапсырмалар",
        "chat_files": "Чат файлдары",
        "project_files": "Жоба файлдары",
        "delete_chat": "Чатты жою",
        "delete_chat_mess": "Чат жойылды",
        "chat_delete_confirm": "Сіз шынымен осы чатты жойғыңыз келе ме?",
        "save": "Сақтау",
        "name_changed": "Чаттың атауы өзгертілді",
        "change_chat_name": "Топтық чаттың атауын өзгерту",
        "press_chat_name": "Чаттың атауын енгізіңіз",
        "member_added": "Қатысушы қосылды",
        "chat_administration_excluded_you": "Чат әкімшілігі сізді шығарды",
        "select_chat": "Чатты таңдаңыз <br/>немесе ",
        "select_chat2": "Чатты таңдаңыз",
        "create_new_chat": "жаңа әңгіме құрыңыз",
        "field_require": "Толтыру міндетті",
        "field_min_require": "Кемінде {min} таңба",
        "field_max_require": "Кемінде {max} таңба",
        "field_email_recuire": "E-mail дұрыс толтырылмаған",
        "wrong_email_or_password": "Логин немесе пароль дұрыс емес",
        "press_user_name": "Пайдаланушы атын енгізіңіз",
        "user_name": "Пайдаланушы аты",
        "group_chat_add": "Чатқа пайдаланушы қосу",
        "personal_dialogue": "Жеке диалог",
        "chat_author": "Чат авторы:",
        "chat_author_can_rename_chat": "Чат авторы чаттың атын өзгерте алады",
        "chat_author_can_delete_chat": "Чат авторы чатты жоя алады",
        "chat_author_can_remove_chat_participants": "Чат авторы чат қатысушыларын жоя алады",
        "chat_menu": "Чат авторы мен модераторлар жаңа қатысушыларды меню арқылы қосуға болады",
        "chat_menu2": "веб-сайттың жоғарғы бөлігінде немесе чат атын басу арқылы",
        "chat_author_can_assign_remove_moderators": "Чат авторы модераторларды тағайындай алады/жоя алады",
        "chat_info_max": "Хабарламаның максималды ұзындығы 1024 таңба",
        "chat_info_del": "Хабарламаларды жарияланғаннан кейін 1 минут ішінде жоюға болады",
        "chat_info_file_size": "Файлдың максималды салмағы 10МБ",
        "chat_info_enter": "Хабарламаларды жіберу үшін Enter пернесін пайдаланыңыз",
        "chat_creator_and_moderators_can_delete_chat_messages": "Чат авторы мен модераторлар чаттағы хабарламаларды жоя алады",
        "chat_info_news_str": "Жаңа жолға өту үшін Shift+Enter пернелерін қолданыңыз",
        "not_fount": "Пайдаланушы табылмады",
        "enter_your_message": "Хабарлама енгізіңіз",
        "attach": "Қосымшаны тіркеу",
        "attached_file": "Тіркелген файл",
        "dead_line": "Соңғы мерзім",
        "priority": "Приоритет",
        "operator": "Жауапты",
        "project": "Жоба",
        "workgroup": "Команда",
        "name_ru": "Атауы",
        "conference": "Конференция",
        "comments_sent": "Пікір жіберілді",
        "open_task": "Тапсырманы ашу",
        "create_and_open": "Сақтау және ашу",
        "create_and_create": "Сақтау және тағы біреуін жасау",
        "create_and_copy": "Сақтау және көшіру",
        "change2": "Өзгерту",
        "task2": "Тапсырма",
        "history": "Тарих",
        "select_task": "Тапсырманы таңдау",
        "task_name": "Тапсырманың атауы",
        "no_time_limit": "Шектеусіз",
        "edit_task": "Тапсырманы өңдеу",
        "change_moderator": "Модератор етіп тағайындау",
        "share_to_chat": "Чатта бөлісу",
        "task_sent": "Тапсырма жіберілді",
        "file_sent": "Файл жіберілді",
        "tasks": "Тапсырмалар",
        "share": "Бөлісу",
        "copy_link": "Сілтемені көшіру",
        "share_telegram": "Telegram-да жіберу",
        "share_whatsapp": "WhatsApp-да жіберу",
        "share_email": "E-mail-ге жіберу",
        "chat_task": "Чаттағы тапсырмалар",
        "crm.goodsordermodel": "Тапсырыс",
        "give_access_to_files": "Файлдарға қол жеткізуді беру",
        "more": "Көбірек",
        "set_order": "Тапсырыс құру",
        "copy_to_clipboard": "Мәтінді көшіру",
        "today": "Бүгін",
        "yesterday": "Кеше",
        "create_chat": "Чат жасау",
        "empty": "Ештеңе табылмады",
        "search": "Іздеу",
        "list_empty": "Тізім бос",
        "online": "Желідесіз",
        "was_online": "Желідесіз болды",
        "typing": "жазып жатыр",
        "for_guests": "Қонақтар үшін",
        "meeting": "Кездесу",
        "project": "Жоба",
        "workgroup": "Топ",
        "open_project": "Жобаны ашу",
        "open_team": "Команданы ашу",
        "invite_link": "Қонақтар үшін шақыру сілтемесі",
        "copy": "Көшіру",
        "open_meeting": "Кездесуді ашу",
        "participant": "қатысушы",
        "participant2": "қатысушының",
        "participant3": "қатысушылар",
        "typing": "жазып жатыр",
        "typing2": "жазып жатыр",
        "typing3": "және",
        "typing4": "жазып жатыр",
        "linkCopied": "Сілтеме көшірілді",
        "participants": "Қатысушылар",
        "chat_tasks": "Чат тапсырмалары",
        "chat_files": "Чат файлдары",
        "project_files": "Жоба файлдары",
        "change_chat_name": "Чат атын өзгерту",
        "press_chat_name": "Чат атын енгізіңіз",
        "close": "Жабу",
        "edit": "Өзгерту",
        "add_user": "Пайдаланушы қосу",
        "remove": "Жою",
        "deleted_success": "Чат жойылды",
        "delete_error": "Чатты жоюда қате",
        "drop_files_here": "Файлдарды мұнда тартыңыз",
        "copied_success": "Хабарлама көшірілді",
        "copy_error": "Көшіру қатесі",
        "copy_message_text": "Хабарлама мәтінін көшіру",
        "conference": "Конференция",
        "author": "Автор",
        "start_date": "Басталу күні",
        "duration": "Ұзақтығы",
        "minutes": "минут",
        "participants": "Қатысушылар",
        "connect": "Байланысу",
        "event": "Оқиға",
        "news": "Жаңалық",
        "man": "адам",
        "man_genitive": "адамның",
        "man_plural": "адам",
        "desc": "Сипаттама",
        "open": "Ашу",
        "chat_not_found": "Чат жоқ",
        "support": "Техникалық қолдау",
        "task2": "Задача #{counter}",
        "comment2": "Пікір: {text}",
        "meeting2": "Жиналыс: {name}",
        "project2": "Жоба: {name}",
        "team2": "Команда: {name}",
        "sprint2": "Спринт: {name}",
        "event2": "Оқиға: {name}",
        "order2": "Тапсырыс: #{counter}",
        "ticket2": "База: {name}",
        "photo": "Сурет",
        "photos": "Суреттер",
        "photosGen": "Суреттердің",
        "fileC": "Файл",
        "fileGenC": "Файлдың",
        "filesC": "Файлдардың",
        "create_group_chat": "Топтық чат жасау",
        "chat_name": "Чаттың атауы",
        "contact_name_placeholder": "Контакттың толық аты",
        "group_chat_info_message": "Топтық чат жасау үшін кемінде 2 қатысушыны таңдау қажет",
        "no_longer_moderator": "арттаушы емес",
        "removed_from_chat": "чаттан шығарылды",
        "give_access_to_files": "Файлдарға қол жеткізуді беріңіз",
        "attach": "Қосымша",
        "project": "Жоба",
        "work_group": "Жұмыс тобы",
        "number": "Нөмір",
        "client": "Клиент",
        "contract": "Келісім-шарт",
        "status": "Статус",
        "no_time_limit": "Уақыт шегі жоқ",
        "duration": "Ұзақтығы",
        "online": "Онлайн",
        "new": "Жаңа",
        "ended": "Аяқталды",
        "participants_count": "Қатысушылар саны",
        "enter_your_message": "Хабарламаңызды енгізіңіз",
        "share_and_stay": "Бөлісіп қалу",
        "share_and_go_to_chat": "Бөлісіп, чатқа өту",
        "delete_template": "Шаблонды жою?",
        "popup_title": "Поп-аптың тақырыбы",
        "form": {
            "title": "Атау",
            "message": "Хабарлама",
            "public": "Публичный",
            "delete_template": "Шаблонды жою?",
            "confirm_delete": "Иә",
            "cancel_delete": "Жоқ"
        },
        "button": {
            "submit": "Растау"
        },
        "errors": {
            "title_required": "«Атауы» алаңы бос болмауы тиіс",
            "text_required": "«Хабарлама» алаңы бос болмауы тиіс",
            "title": "Атауда қате",
            "text": "Хабарламада қате"
        },
        "edit_template": "Шаблонды өңдеу",
        "new_template": "Жаңа шаблон",
        "create": "Жасау",
        "template_save_error": "Шаблонды сақтау қатесі",
        "template_create_error": "Шаблонды жасау қатесі",
        "failed_to_add_users": "Пайдаланушыларды қосу мүмкін болмады"
    }
}
