export default {
    gallery: {
        "addGalleryPhoto": "Галереяға сурет қосу",
        "photo_count": "Барлығы {count} сурет",
        "main_photo": "Басты сурет",
        "edit": "Файлды өңдеу",
        "delete": "Файлды жою",
        "description": "Суреттің сипаттамасы",
        "setMainPhoto": "Басты сурет ретінде орнату",
        "addPhoto": "Сурет қосу",
        "save": "Сақтау",
        "cancel": "Бас тарту",
        "close": "Жабу",
        "fileUpdated": "Файл сәтті жаңартылды",
        "deleteMessage": "Файлды жойғыңыз келетініне сенімдісіз бе?",
        "delete2": "Жою",
        "fileDeleted": "Файл жойылды",
        "deletedError": "Жою қатесі",
        "fileLoadSuccess": "Файлдар сәтті жүктелді",
        "fileLoadError": "Файлдарды жүктеу кезінде қате орын алды. Қайталап көріңіз."
    }
}